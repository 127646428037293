export default [
  {
    key: "lead_name",
    label: "Name",
    class: "text-left",
    visible: true,
  },
  {
    key: "account",
    label: "Account",
    class: "text-left",
    visible: true,
  },
  {
    key: "mobile",
    label: "Phone Number",
    class: "text-center",
    visible: true,
  },
  {
    key: "ub",
    label: "UB",
    class: "text-center",
    visible: true,
  },
  {
    key: "id",
    label: "ID",
    class: "text-center",
    visible: true,
  },
  {
    key: "last_upload",
    label: "Last Upload",
    class: "text-center",
    visible: true,
  },
  {
    key: "user_name",
    label: "Approved By",
    class: "text-center",
    visible: false,
  },
  {
    key: "actions",
    label: "Actions",
    class: "text-center",
    visible: true,
  },
];

import { amgApi } from "@/service/axios";

class AppFilesService {
  async getFilesPending(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/get-files-pending",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getFilesPending");
    }
  }

  async approveFileCommission(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/approve-file-commission",
        params
      );
      return data;
    } catch (error) {
      console.log("Error approveFileCommission");
    }
  }
}

export default new AppFilesService();

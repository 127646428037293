<template>
  <div>
    <div class="border-top-primary border-3 border-table-radius px-0">
      <filter-slot
        :filter-principal="filterPrincipal"
        :filter="filter"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refApps'].refresh()"
      >
        <template #table>
          <b-table
            slot="table"
            ref="refApps"
            small
            :items="myProvider"
            :fields="fieldsVisible"
            primary-key="id"
            table-class="text-nowrap"
            responsive="sm"
            show-empty
            sticky-header="70vh"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #cell(lead_name)="data">
              <router-link
                :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                v-if="
                  currentUser.role_id === 1 ||
                  currentUser.role_id === 17 ||
                  currentUser.role_id === 2 ||
                  currentUser.role_id === 14
                "
                :to="{
                  name: 'ce-customer-service-dashboard',
                  params: {
                    idClient: data.item.account_id,
                    hasAccess: true,
                    wayAccess: 4,
                    typeAccess: 6,
                  },
                }"
              >
                {{ data.item.lead_name }}
              </router-link>
              <span v-else>{{ data.item.lead_name }}</span>
            </template>
            <template #cell(id)="data">
              <ul class="list-unstyled m-0" v-if="data.item.id">
                <li v-for="file in data.item.id" :key="file.id">
                  <span
                    @click="showFileModal(file, data.item)"
                    class="text-primary clickable"
                  >
                    <feather-icon :icon="fileIcon(file.extension)" />
                    {{ file.name }}
                  </span>
                  <b-badge
                    v-if="file.status === 1"
                    variant="light-success"
                    class="ml-1"
                  >
                    APPROVED
                  </b-badge>
                  <b-badge
                    v-else-if="file.status === null"
                    variant="light-warning"
                    class="ml-1"
                  >
                    WAITING <feather-icon icon="AlertCircleIcon" />
                  </b-badge>
                  <b-badge
                    v-else-if="file.status === 0"
                    variant="light-danger"
                    class="ml-1"
                  >
                    REJECTED <feather-icon icon="AlertCircleIcon" />
                  </b-badge>
                </li>
              </ul>
            </template>
            <template #cell(ub)="data">
              <ul class="list-unstyled m-0">
                <li v-for="(file, index) in data.item.ub" :key="index">
                  <span
                    @click="showFileModal(file, data.item)"
                    class="text-primary clickable"
                  >
                    <feather-icon :icon="fileIcon(file.extension)" />
                    {{ file.name }}
                  </span>
                  <b-badge
                    v-if="file.status === 1"
                    variant="light-success"
                    class="ml-1"
                  >
                    APPROVED
                  </b-badge>
                  <b-badge
                    v-else-if="file.status === null"
                    variant="light-warning"
                    class="ml-1"
                  >
                    WAITING <feather-icon icon="AlertCircleIcon" />
                  </b-badge>
                  <b-badge
                    v-else-if="file.status === 0"
                    variant="light-danger"
                    class="ml-1"
                  >
                    REJECTED <feather-icon icon="AlertCircleIcon" />
                  </b-badge>
                </li>
              </ul>
            </template>
            <template #cell(actions)="data">
              <div
                v-if="
                  data.item.commission_status != null || isTechnicalAssistant
                "
              >
                <b-badge
                  v-if="data.item.commission_status === 1"
                  variant="light-success"
                  class="ml-1"
                >
                  APPROVED
                </b-badge>
                <b-badge
                  v-else-if="data.item.commission_status === 0"
                  variant="light-danger"
                  class="ml-1"
                >
                  REJECTED
                </b-badge>
                <b-badge v-else variant="light-warning" class="ml-1">
                  WAITING
                </b-badge>
              </div>
              <div v-else>
                <feather-icon
                  v-if="leastOneFileRejected(data.item)"
                  class="cursor-pointer text-primary mr-1"
                  icon="ThumbsUpIcon"
                  size="18"
                  @click="approveCommission(1, data.item)"
                />
                <feather-icon
                  v-else
                  class="cursor-pointer text-danger"
                  icon="ThumbsDownIcon"
                  size="18"
                  @click="approveCommission(0, data.item)"
                />
              </div>
            </template>
            <template #cell(last_upload)="data">
              {{ data.item.last_upload | myGlobalDay }}
            </template>
          </b-table>
        </template>
      </filter-slot>
    </div>

    <b-modal
      id="modal-file"
      ref="modalFile"
      size="md"
      title="Analyze file"
      @hidden="closeModalFile"
    >
      <div>
        <b-input-group>
          <b-input-group-prepend style="width: 48%" variant="light">
            <b-button
              :variant="isDarkSkin ? 'primary' : 'light'"
              class="w-100 disableBtn"
              >FILE
            </b-button>
          </b-input-group-prepend>

          <b-input-group-append is-text style="display: grid; width: 52%">
            <div v-if="isImage(file.extension)">
              {{ file.name }}
              <feather-icon
                icon="EyeIcon"
                class="ml-1 text-warning clickable"
                @click="openImage(file.link)"
              />
            </div>
            <a :href="file.link" target="_blank" class="ml-2" v-else>
              <feather-icon :icon="fileIcon(file.extension)" size="18" />
              {{ file.name }}
            </a>
          </b-input-group-append>
        </b-input-group>
      </div>
      <template #modal-footer>
        <b-button
          variant="success"
          @click="changeStatusFile(1, file)"
          v-if="file.status !== 1 && !isCommission"
        >
          <feather-icon
            class="cursor-pointer text-white"
            icon="ThumbsUpIcon"
            size="18"
          />
          APPROVE
        </b-button>
        <b-button
          variant="danger"
          @click="changeStatusFile(0, file)"
          v-if="
            file.status !== 1 ||
            (file.commission_status != 1 && !isTechnicalAssistant)
          "
        >
          <feather-icon
            class="cursor-pointer text-white"
            icon="ThumbsDownIcon"
            size="18"
          />
          REJECT
        </b-button>
        <b-button variant="secondary" @click="closeModalFile"> Close </b-button>
      </template>
    </b-modal>
    <modal-set-observation
      v-if="openObservationModal"
      @close="openObservationModal = false"
      @sendNotification="sendStatus"
    />
    <cool-light-box
      :items="itemImage"
      :index="indexImage"
      @close="indexImage = null"
    />
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox"
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css"
import { mapGetters } from "vuex"
import FieldsApp from "@/views/ce-digital/sub-modules/customer-service/views/file-app/data/fields.files.js"
import FilterApp from "@/views/ce-digital/sub-modules/customer-service/views/file-app/data/filter.files.js"

import AppFilesService from "@/views/ce-digital/sub-modules/customer-service/views/file-app/service/files.service.js"
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services"
import ModalSetObservation from "@/views/commons/components/clients/dashboard/options/files-module/components/ModalSetObservation.vue"
import FinancialCommissionsService from "@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.service"
export default {
  components: {
    ModalSetObservation,
    CoolLightBox,
  },
  data() {
    return {
      totalRows: 0,
      startPage: 1,
      toPage: 1,
      filter: FilterApp,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      fieldsApp: FieldsApp,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Name or phone number...",
        model: "",
      },
      items: [],
      file: {},
      observationItem: {},
      openObservationModal: false,
      itemImage: [],
      indexImage: null,
      isBusy: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isCommission() {
      return this.$route.meta.is_commission
    },
    fieldsVisible() {
      if (this.isTechnicalAssistant) this.fieldsApp[7].label = "Status"
      if (this.isCommission) {
        this.fieldsApp[6].visible = true
        this.fieldsApp[7].visible = true
      } else {
        this.fieldsApp[6].visible = false
        this.fieldsApp[7].visible = false
      }
      return this.fieldsApp.filter((field) => field.visible)
    },
    moduleId() {
      return this.$route.matched[0].meta.module
    },
  },
  methods: {
    fileIcon(doc = "") {
      let d = doc ? doc : ""
      switch (d.toLowerCase()) {
        case "pdf":
          return "FileTextIcon"
          break
        case "docx":
          return "FileTextIcon"
          break
        case "xlsx":
          return "FileTextIcon"
          break
        case "xls":
          return "FileTextIcon"
          break
        case "png":
          return "ImageIcon"
          break
        case "jpg":
          return "ImageIcon"
          break
        default:
          return "FileIcon"
          break
      }
    },
    leastOneFileRejected(item) {
      item.id = item.id || []
      let arr = item.id.concat(item.ub)
      arr = arr.filter((item) => item)
      let arrRejected = arr.filter((item) => item.status === 0)
      return arrRejected.length === 0
    },
    isImage(extension) {
      return ["png", "jpg", "jpeg"].includes(extension?.toLowerCase())
    },
    showFileModal(file, item) {
      this.file = {
        ...file,
        account: item.account_id,
        id: file.id,
        commission_status: item.commission_status,
      }
      this.$refs.modalFile.show()
    },
    closeModalFile() {
      this.$refs.modalFile.hide()
    },
    async myProvider(ctx) {
      const params = {
        page: ctx.currentPage,
        per_page: ctx.perPage,
        search: this.filterPrincipal.model,
        isCommission: this.isCommission,
        finish: this.$route.meta.finish,
      }
      const data = await AppFilesService.getFilesPending(params)
      this.items = data.data
      this.startPage = data.from
      this.paginate.currentPage = data.current_page
      this.paginate.perPage = data.per_page
      this.nextPage = this.startPage + 1
      this.endPage = data.last_page
      this.totalRows = data.total
      this.toPage = data.to
      return this.items || []
    },
    async changeStatusFile(status, item) {
      this.observationItem = item
      if (status === 0) {
        this.openObservationModal = true
      } else {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          await this.sendStatus({ status, observation: {} })
        }
      }
    },
    async sendStatus({ status, observation }) {
      const params = {
        status,
        title: observation.title,
        description: observation.description,
        user_id: this.currentUser.user_id,
        id: this.observationItem.id,
        account: this.observationItem.account,
      }
      const data = await ClientsOptionsServices.changeStatusFilesApp(params)
      if (data.status === 200) {
        this.openObservationModal = false
        this.closeModalFile()
        this.showSuccessSwal()
        this.$refs.refApps.refresh()
      }
    },

    openImage(image) {
      if (this.itemImage.length > 0) this.itemImage = []
      this.itemImage.push(image)
      this.indexImage = 0
    },

    async approveCommission(status, item) {
      const response = await this.showConfirmSwal()
      if (response.isConfirmed) {
        const params = {
          id: item.id_commission,
          status,
        }
        const data = await AppFilesService.approveFileCommission(params)
        if (data.status === 200) {
          if (status === 1) {
            const payload = {
              commision_type: 8,
              agent_id: item.commission_user_id,
              client_account: item.account_id,
              module_id: this.moduleId,
              base_amount: 100,
            }
            try {
              await FinancialCommissionsService.createCommissionCed(payload)
              this.showSuccessSwal()
              this.$refs.refApps.refresh()
              return
            } catch (error) {
              console.error(error)
            }
          }
          this.showSuccessSwal()
          this.$refs.refApps.refresh()
        }
      }
    },
  },
}
</script>

<style></style>
